.paraImage {
    width: 100vw;
    height: 70vh;
    object-fit: cover;
    object-position: 0% 50%; /* Initial position */
    transition: object-position 0.1s linear; /* Smooth transition */
}

.parallax-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100vw;
    overflow: hidden;
}

.image-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0; /* Initially hidden */
    transition: opacity 1s ease-in-out; /* Smooth fade-in */
}

.image-content.visible {
    opacity: 1; /* Fade-in effect */
}

.image-content h1 {
    color: white;
    font-size: 2em;
    margin: 0.5em 0;
}

.image-content button {
    padding: 0.5em 1em;
    font-size: 1em;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    cursor: pointer;
}

.image-wrapper {
    position: relative;
    width: 100vw;
    height: 70vh;
}
