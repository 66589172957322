@import url('https://fonts.googleapis.com/css?family=Tangerine');
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.315);
    box-sizing: border-box;
}

.links-left{
    display: flex;
    align-items: center;
    flex: 1;
}

.links-right {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 10px;
}

.site-title-div {
    display: flex;
    justify-content: center;
    flex: 1;
}

.site-title {
    color: #000000;
    font-size: 2.9em;
    text-align: center;
    font-weight: 500;
    font-family: 'Tangerine';
}

.navbar a {
    margin-left: 16px;
    text-decoration: none;
    color: #000000;
    padding: 6px;
}

.links-left a:hover {
    color: #ffffff;
}

.links-left a {
    font-size: 18px;
}
