body {
    height: 100vh;
    width: 100%;
    background-color: rgb(107, 105, 105);
    margin: 0;
    overflow-y: scroll;
    overflow-x: hidden;
}

.image-track-container {
    display: flex;
    justify-content: center; /* Center the track horizontally */
    margin: 50px 0; /* Add some margin to avoid overlapping other content */
}

#image-track {
    display: flex;
    gap: 4vmin;
    transition: transform 0.3s ease-out; /* Add a smooth transition */
}

.image {
    width: 40vmin;
    height: 56vmin;
    object-fit: cover;
    object-position: 100% 50%;
    user-select: none;
    cursor: pointer;
    transition: object-position 0.3s ease-out, transform 0.3s ease-out; /* Add a smooth transition */
}

.image:hover {
    cursor: pointer;
    transform: scale(1.13);
}