.vidSection {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aliceblue;
    padding: 20px;
    height: 60vh;
    width: 100vw;
    box-sizing: border-box;
}

.videoPlayer {
    flex-shrink: 0;
    margin-right: 4vw;
}

.textContent {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers the text content vertically */
    align-items: center; /* Centers the text content horizontally */
}

.divTitle {
    margin: 0;
    padding-bottom: 20px; /* Space between title and description */
    text-align: center; /* Center the title */
}

.vidDescription {
    margin: 0;
    text-align: center; /* Center the description */
    padding-right: 2vw;
}
