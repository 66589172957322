@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.trackDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 100%;
  transform: translate(30%, 0%);
  margin: 10px;
}

