.footer {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    vertical-align: bottom;
    gap: 4px;
    background-color: #333;
    padding: 10px;
  }

  .links-left{
    display: flex;
    align-items: center;
    flex: 1;
}

.links-right {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 10px;
}

.footer a {
    margin-left: 16px;
    text-decoration: none;
    color: #ffffff;
    padding: 6px;
}

.footer a:hover {
    color: #b1b1b1;
}

.footer a {
    font-size: 18px;
}
