.contactdiv{
    max-width: 550px;
    margin: auto;
    padding-top: 15px;
}

.contactSubmit{
    padding-top: 10px;
    padding-bottom: 20px;
}

